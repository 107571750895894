
<template>
  <v-list-item
    v-if="user.role !== 'scan'"
    class="rv-item-event"
    two-line
    :to="{
      name: user.role === 'scan' ? 'Scan' : 'MyEvent',
      params: { uid: item.uid },
    }"
  >
    <v-list-item-action>
      <v-card outlined class="px-3 py-1 | text-center">
        <h3 class="text-h5" style="line-height: 1">
          {{ dateDay(item.startDate) }}
        </h3>
        <h5 class="text-subtitle-2 | text-uppercase" style="line-height: 1">
          {{ dateDayName(item.startDate) }}
        </h5>
      </v-card>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-subtitle>Nombre:</v-list-item-subtitle>
      <v-list-item-title
        class="primary--text font-weight-semibold"
        v-text="item.name"
      />
    </v-list-item-content>
    <v-list-item-action>
      <v-icon> mdi-chevron-right </v-icon>
    </v-list-item-action>
  </v-list-item>

  <!-- item: scan -->
  <v-list-item
    v-else
    two-line
    class="rv-item-event"
    :to="{ name: mode ? 'ScannerEvent' : 'Scan', params: { uid: item.uid }, query: {name: item.name} }"
  >
    <v-list-item-action>
      <v-card outlined class="px-3 py-1 | text-center">
        <h3 class="text-h5" style="line-height: 1">
          {{ dateDay(item.startDate) }}
        </h3>
        <h5 class="text-subtitle-2 | text-uppercase" style="line-height: 1">
          {{ dateDayName(item.startDate) }}
        </h5>
      </v-card>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-subtitle>Nombre:</v-list-item-subtitle>
      <v-list-item-title
        class="primary--text font-weight-semibold"
        v-text="item.name"
      />
    </v-list-item-content>
    <v-list-item-action>
      <v-icon> mdi-chevron-right </v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  props: ["item"],

  computed: {
    ...mapState(["mode"]),
    ...mapState("auth", ["user"]),
  },

  methods: {
    dateDay(v) {
      var date = v.toDate();
      date = moment(date).format("DD");
      return date;
    },
    dateDayName(v) {
      var date = v.toDate();
      date = moment(date).format("ddd");
      return date.replace(".", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.rv-item-event {
  font-weight: 500;
}
</style>