<template>
  <div class="events--home">
    <!-- Header -->
    <app-header
        title="Eventos"
        :level="0"
        :btn="true"
        :loading="loading"
        @input="dialog = $event"
    />

    <!-- Content -->
    <v-container fluid class="pa-4 pa-md-6">
      <!-- Header -->
      <v-row align="center" v-if="!$vuetify.breakpoint.mobile">
        <v-col><h1>Eventos</h1></v-col>
        <v-col
            class="text-end"
            v-if="this.$store.state.auth.user.role === 'owner'"
        >
          <v-btn large depressed color="primary" @click="dialog = true" :disabled="disabled">
            Crear nuevo evento
          </v-btn>
        </v-col>
      </v-row>

      <!-- List -->
      <v-row>
        <v-col>
          <template v-for="(item, index) in items">
            <v-card :key="index" class="mb-3" flat>
              <item-event :item="item"/>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog: Create New Event -->
    <create-event v-model="dialog" @close="dialog = $event"/>
  </div>
</template>

<script>
import AppHeader from "@/layouts/components/Header.vue";
import CreateEvent from "./Create.vue";
import ItemEvent from "./Item.vue";
import {mapActions, mapState} from "vuex";

export default {
  components: {AppHeader, CreateEvent, ItemEvent},

  data: () => ({
    items: [],
    dialog: false,
    loading: false,
  }),

  computed: {
    ...mapState("config", ["disabled"])
  },

  methods: {
    ...mapActions("events", ["eventsActive"]),
  },

  async created() {
    window.scrollTo(0, 0)
    this.loading = true;
    await this.eventsActive().then((resp) => (this.items = resp));
    this.loading = false;
  },
};
</script>